<template>
	<div
		class="side full-height overflow-y-auto"
	>
		<div
			class="side-container flex-column "
		>
			<ul
				class="side-content pb-50"
			>
				<template
					v-for="(item, index) in menu"
				>
					<li
						:key="'menu_' + item.path + '_' + index"
						class="li-side-content "
						:class="'li-side-depth-' + item.depth"
					>
						<div
							class="pa-3-10 justify-space-between"
						>
							<span
								class="font-weight-bold flex-column justify-center ptb-5 size-px-12"
							>{{ item.name }}</span>
						</div>
						<ul>
							<template
								v-for="(sub, sub_index) in item.subMenus"
							>
								<li
                                    v-if="sub.front !== 'branchAutoSettlement'"
									:key="'sub_' + index + '_' + sub_index + '_' + item.path + sub.path"
									class="pa-5-10 li-side-sub "
									:class="$route.name == sub.front ? 'on' : 'cursor-pointer'"
									@click="toPush(item, sub)"
								>
									<div
										class="justify-space-between"
									>
										<span class="flex-column justify-center ptb-5 size-px-12">{{ sub.name }}</span>
										<v-icon
											v-show="$route.name != sub.front"
											class="color-bbb"
										>mdi mdi-chevron-right</v-icon>
									</div>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Side'
	,props: ['user', 'menu']
	,data: function(){
		return {
			items: []
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){

				return item.sub.filter(function(sub){

					return sub
				})
			})
		}
	}
	,methods: {
		toPush: function(item, sub){
			this.$layout.push( { name: sub.front})
		}
	}
    ,created() {
        this.$layout = this.$root.$children[0].$children[0]
    }

}
</script>


<style>
	.side {
		background-color: #273956;
		flex-basis: 220px;
	}

	.side-container {
		color: #eee;
	}

	.li-side-sub {
		background-color: #22314B;
		color: #bbb;
	}
	.side .on {
		color: #eee;
		background-color: #22314B;
	}
</style>